import { useEffect, useMemo, useRef, useState } from 'react';

import { ReviewView } from '@zola/svc-marketplace-ts-types';
import { XIcon } from '@zola/zola-ui/src/components/SvgIconsV3/X';
import Portal from '@zola/zola-ui/src/components/util/Portal';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

import { StorefrontDetailsStickyFooter } from '~/pages/couples/storefronts/components/StorefrontDetailsStickyFooter';
import { useStorefrontDetailsUnsafe } from '~/pages/couples/storefronts/contexts/StorefrontDetailsContext';
import { MappedReviewView } from '~/types/responseTypes';

import CachedImage from '../../images/CachedImage';
import { HeaderReviewInfo } from './HeaderReviewInfo';
import {
  CLOSE_LIGHTBOX_ICON_SIZE,
  CloseLightboxBtn,
  Lightbox,
  LightboxImageContainer,
  MobileContainer,
  ModalContainer,
  ScreenReaderText,
  StyledTitle2,
  TitleBar,
  TitleBarLeft,
} from './Lightbox.styles';

const getReviewForPhoto = (photoUuid: string, reviews: MappedReviewView[] | ReviewView[]) => {
  // @ts-ignore TS can't figure out what the `reviews` type is for some reason
  return reviews.find((review) => review.reviewImageIds?.includes(photoUuid));
};

const getReviewUuidForPhoto = (photoUuid: string, reviews: MappedReviewView[] | ReviewView[]) => {
  return getReviewForPhoto(photoUuid, reviews)?.reviewUuid || undefined;
};

type MobileLightboxProps = {
  photoUuids: string[];
  initialUuid: string;
  onClose: () => void;
  /**
   * Reviews associated with the photos, used to show review info in the title bar
   */
  reviews?: MappedReviewView[] | ReviewView[];
  /**
   * Show the storefront sticky footer
   */
  showFooter?: boolean;

  disableControls?: boolean;
};

/**
 * Lightbox for viewing storefront cover/main photos or review photos on mobile
 */
const MobileLightbox = ({
  photoUuids,
  initialUuid,
  onClose,
  reviews = [],
  showFooter = false,
  disableControls = false,
}: MobileLightboxProps) => {
  // We use the "unsafe" hook because the lightbox is used in vendor management, where it doesn't
  // make sense to wrap with the couple's storefront details provider
  const { storefrontDetails } = useStorefrontDetailsUnsafe() || {};
  let initialPhotoIndex = photoUuids.findIndex((photoUuid) => photoUuid === initialUuid);

  if (initialPhotoIndex === -1) {
    initialPhotoIndex = 0;
  }

  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [openPhotoUuid, setOpenPhotoUuid] = useState<string | null>(
    photoUuids[initialPhotoIndex] || null
  );
  const openPhotoReview = useMemo(() => {
    if (!openPhotoUuid) return null;
    return getReviewForPhoto(openPhotoUuid, reviews) || null;
  }, [openPhotoUuid, reviews]);

  const hed = useMemo(() => {
    if (openPhotoUuid && openPhotoReview) {
      return openPhotoReview.title;
    }
    if (storefrontDetails) {
      return storefrontDetails.name;
    }
    return null;
  }, [openPhotoReview, openPhotoUuid, storefrontDetails]);

  useEffectOnce(() => {
    if (document) {
      const pageContainer = document.getElementById('root');
      if (pageContainer) {
        setPortalContainer(pageContainer);
      }
    }
  });

  /**
   * Scrolls to selected photo on opening lightbox when viewing review photos.
   * Not relevant when viewing default storefront cover/main photos.
   */
  useEffect(() => {
    if (reviews.length === 0) return;
    const delayedScroll = setTimeout(() => {
      const photoElement = containerRef.current?.querySelector(
        `[data-photo-index="${initialPhotoIndex}"]`
      );
      if (photoElement && portalContainer) {
        photoElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
    return () => clearTimeout(delayedScroll);
  }, [initialPhotoIndex, portalContainer, reviews]);

  /**
   * Updates review info as you scroll through review photos based on photo in viewport.
   * Not relevant when viewing default storefront cover/main photos.
   */
  useEffect(() => {
    if (reviews.length === 0) return;
    let timeoutId: NodeJS.Timeout;
    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const currentReviewUuid = entry.target.getAttribute('data-review-uuid');
          const currentPhotoIndex = Number(entry.target.getAttribute('data-photo-index'));
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            // Update openPhoto with intersecting photo only if review is different
            if (openPhotoReview?.reviewUuid !== currentReviewUuid) {
              setOpenPhotoUuid(photoUuids[currentPhotoIndex]);
              observer.disconnect();
            }
          }, 300);
        }
      });
    };
    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });
    // Start observing each CachedImage element
    // The timeout is necessary to make sure the photo elements are in the DOM and observable
    setTimeout(() => {
      const photoElements = containerRef.current?.querySelectorAll('[data-photo-index]');
      photoElements?.forEach((image) => {
        observer.observe(image);
      });
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      observer.disconnect();
    };
  }, [openPhotoReview, openPhotoUuid, photoUuids, reviews]);

  if (portalContainer === null) return null;

  return (
    <Portal portalContainer={portalContainer}>
      <Lightbox onClick={() => null} device="mobile">
        <LightboxImageContainer ref={containerRef} tabIndex={-1}>
          <ModalContainer>
            <TitleBar>
              <TitleBarLeft withReviewInfo={reviews.length > 0}>
                <StyledTitle2 presentation="h6" color="WHITE_100">
                  {hed}
                </StyledTitle2>
                <HeaderReviewInfo openPhotoReview={openPhotoReview} />
              </TitleBarLeft>
              <CloseLightboxBtn onClick={onClose}>
                <XIcon height={CLOSE_LIGHTBOX_ICON_SIZE} width={CLOSE_LIGHTBOX_ICON_SIZE} />
                <ScreenReaderText>Close</ScreenReaderText>
              </CloseLightboxBtn>
            </TitleBar>
            <MobileContainer>
              {photoUuids.map((photoUuid, photoIndex) => (
                <CachedImage
                  data-photo-index={photoIndex}
                  data-review-uuid={getReviewUuidForPhoto(photoUuid, reviews)}
                  key={photoUuid}
                  uuid={photoUuid}
                  srcSizes={[{ width: 320 }, { width: 640 }]}
                  sizes="100vw"
                  fit="crop"
                  quality={75}
                />
              ))}
            </MobileContainer>
          </ModalContainer>
        </LightboxImageContainer>
        {!disableControls && showFooter && (
          <StorefrontDetailsStickyFooter forceShow onClick={onClose} />
        )}
      </Lightbox>
    </Portal>
  );
};

export default MobileLightbox;
